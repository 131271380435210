import { Avatar, Badge, Flex, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import React, { Ref } from "react";
import { useSelector } from "react-redux";
import { FinanceSettingsApi } from "../../apis";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { IApprovelRuleLevel } from "../../interfaces/IApprovelRuleLevel";
import { IGeneral } from "../../interfaces/IGeneral";

export function RoleHistoryList({ action, setAction, tableRef }: { action: any; setAction: any; tableRef: Ref<ITableRef> | undefined }) {
  const [filters] = React.useState({ query: "" });
  const { bankNames, financeRoleStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    bankNames,
    financeRoleStatus,
    onClick: (key, item) => {
      switch (key) {
        case "bank-role": {
          // setAction(["bank-role", item as any]);
          break;
        }
      }
    },
  });

  return (
    <div>
      <Table
        placeholder={
          <Text fw={500} ta="center">
            Банктай харилцах ажилтны эрх тохируулаагүй байна
          </Text>
        }
        ref={tableRef}
        name="/usr/finance_settings/finance_role"
        columns={columns}
        filters={filters}
        loadData={(data) => FinanceSettingsApi.role_list(data!)}
      />
    </div>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IApprovelRuleLevel) => void;
  bankNames: any[];
  financeRoleStatus: any[];
};

const useHeader = ({ onClick, bankNames, financeRoleStatus }: HeaderProps): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  // {
  //   title: "Үйлдэл",
  //   align: "center",
  //   render: (record) => {
  //     return (
  //       <Tooltip label="Эрх тохиргоо">
  //         <ActionIcon variant="light" onClick={() => onClick("bank-role", record)} color="">
  //           <IconCheckbox />
  //         </ActionIcon>
  //       </Tooltip>
  //     );
  //   },
  // },
  {
    title: "Банкны нэр",
    sorter: true,
    render: (record) => (
      <>
        <Flex align="center" gap={"xs"}>
          <Avatar size={28} src={bankNames?.find((item: any) => item.code === record?.bank)?.icon || "-"}>
            <IconPhoto />
          </Avatar>
          <Text>{bankNames?.find((item: any) => item.code === record?.bank)?.name || "-"}</Text>
        </Flex>
      </>
    ),
  },
  {
    title: "Бизнесийн #",
    sorter: true,
    render: (record) => {
      return record?.business ? <Text># {record?.business?.refCode || "-"}</Text> : "-";
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    render: (record) => {
      return record?.business ? <Text w={"max-content"}>{record?.business?.profileName || "-"}</Text> : "-";
    },
  },
  {
    title: "SCF/DCF санхүү",
    sorter: true,
    render: (record) => {
      return record?.user ? (
        <Flex gap={"sm"} align="center" w={"max-content"}>
          <Avatar src={record?.user?.avatar} size={32} />
          <div>
            <Text tt="capitalize">
              {record.user?.lastName ? record.user?.lastName[0] + ". " : ""} {record?.user?.firstName}
            </Text>
          </div>
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Хэрэглэгчийн нэр",
    sorter: true,
    render: (record) => {
      return <Text>{record?.user?.username || "-"}</Text>;
    },
  },
  {
    title: "И-мэйл",
    sorter: true,
    render: (record) => {
      return <Text w={"max-content"}>{record?.user?.email || "-"}</Text>;
    },
  },
  {
    title: "Гар утас",
    sorter: true,
    render: (record) => {
      return <Text w={"max-content"}>{record?.user?.phone || "-"}</Text>;
    },
  },
  {
    title: "Эрх статус",
    sorter: true,
    render: (record) => {
      return <Badge w={"max-content"}>{financeRoleStatus.find((item: any) => item.code === record.financeRoleStatus)?.name || "-"}</Badge>;
    },
  },
];
