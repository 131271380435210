import { Alert, Avatar, Button, Divider, Flex, Grid, Group, LoadingOverlay, Text, UnstyledButton } from "@mantine/core";
import { IconAlarm, IconCirclePlus, IconInfoCircle, IconSettings2, IconTrash, IconUserPlus } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { ApprovelApi } from "../../apis";
import { IApprovelRuleLevel } from "../../interfaces/IApprovelRuleLevel";
import { IGeneral } from "../../interfaces/IGeneral";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { MultiSelectField } from "../form/multi-select-field";
import { SelectField } from "../form/select-field";
import { SelectItemField } from "../form/select-item-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";

type LevelType = {
  level: number;
  userIds: string[];
};

const schema = yup.object({
  businessId: yup.string().required("Заавал бөглөнө үү!").nullable(),
  branchId: yup.string().required("Заавал бөглөнө үү!").nullable(),
  module: yup.string().required("Заавал бөглөнө үү!").nullable(),
  systemAction: yup.string().required("Заавал бөглөнө үү!").nullable(),
  name: yup.string().required("Заавал бөглөнө үү!").nullable(),
  description: yup.string().required("Заавал бөглөнө үү!").nullable(),
  levels: yup.array().of(
    yup.object().shape({
      userIds: yup.array().min(1, "Заавал бөглөнө!").of(yup.string()).typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
    }),
  ),
});

type Props = {
  action: any[];
  setAction: any;
  reload: any;
};

type IFormdata = {
  businessId: string;
  branchId: string;
  module: string;
  systemAction: string;
  name: string;
  description: string;
  levels: IApprovelRuleLevel[];
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  position: string;
  item?: any;
}

export function ApprovelRuleForm({ action, reload, setAction }: Props) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { businesses, actions, modules, users, branches } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<IFormdata>({
    businessId: "",
    branchId: "",
    module: "",
    systemAction: "",
    name: "",
    description: "",
    levels: [
      {
        level: 1,
        userIds: undefined,
      },
    ],

    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await ApprovelApi.create({
        businessId: data.businessId,
        branchId: data.branchId,
        module: data.module,
        systemAction: data.systemAction,
        name: data.name,
        description: data.description,
        levels: data.levels,
      });
      reload();
      setAction([]);
      setLoading(false);
      message.success("Амжилттай хяналтын дүрмийг нэмлээ!");
    } catch (err) {
      message.error((err as HttpHandler)?.message! || "Алдаа гарлаа");
      setLoading(false);
    }
  };

  const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(({ image, label, position, item, ...others }: ItemProps, ref) => (
    <Flex align={"center"} gap={8} ref={ref} {...others}>
      <Group>
        <Avatar src={item?.avatar} />
        <div>
          <Text size="sm">
            {item?.lastName ? item?.lastName[0] + ". " : ""} {label}
          </Text>
          <Text size="xs" opacity={0.65}>
            {position}
          </Text>
        </div>
      </Group>
    </Flex>
  ));

  const SelectItemBusiness = React.forwardRef<HTMLDivElement, ItemProps>(({ image, label, position, item, ...others }: ItemProps, ref) => (
    <Flex align={"center"} gap={8} ref={ref} {...others}>
      <Group>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            # {item?.refCode}
          </Text>
        </div>
      </Group>
    </Flex>
  ));

  const onRemove = (index: any, result: [], setFieldValue: any) => {
    result.splice(index, 1);
    const results = result.map((c: any, i: number) => ({
      level: i + 1,
      userIds: c?.userIds,
    }));

    setFieldValue("levels", results);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <LoadingOverlay visible={loading} />
            <Divider color="gray" />
            <Grid>
              <Grid.Col span={4}>
                <SelectItemField
                  searchable
                  required
                  name="businessId"
                  label="Бизнесийн нэр:"
                  placeholder="Сонгох"
                  clearable
                  onChange={(e) => {}}
                  options={businesses.map((c: any) => ({ label: c.profileName, value: c.id, item: c }))}
                  itemComponent={SelectItemBusiness}
                  filter={(value: any, item: any) =>
                    item.label.toLowerCase().includes(value.toLowerCase().trim()) || item.value.toLowerCase().includes(value.toLowerCase().trim())
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField name="branchId" label="Хамрах хүрээ:" placeholder="Сонгох" options={branches.map((c: any) => ({ label: c.name, value: c.id }))} />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  name="module"
                  label="Системийн модуль:"
                  placeholder="Сонгох"
                  options={modules.map((c: any) => ({ label: c.name, value: c.code }))}
                  onChange={() => {
                    setFieldValue("systemAction", undefined);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  disabled={!values.module}
                  name="systemAction"
                  label="Системийн үйлдэл:"
                  placeholder="Сонгох"
                  options={actions.filter((i: any) => i.module === values.module).map((c: any) => ({ label: c.name, value: c.code }))}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField name="name" label="Дүрмийн нэр:" placeholder="Дүрмийн нэрийг оруулна уу." />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextareaField name="description" placeholder="Тайлбар оруулах" label="Тайлбар оруулах" />
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХЯНАЛТЫН ДҮРЭМ" />
              </Grid.Col>

              <Grid.Col span={12}>
                {values?.levels?.length === 3 && (
                  <Alert icon={<IconInfoCircle size="1rem" />} title="Санамж!" color="yellow">
                    Хэрэглэгч та хамгийн ихдээ гурван түвшин нэмэх боломжтой. Мөн өмнөх түвшинд сонгогдсон хэрэглэгчийг дахин сонгож болохгүйг анхаарна уу
                    баярлалаа.
                  </Alert>
                )}
              </Grid.Col>

              {values?.levels?.map((level: LevelType, index: number) => (
                <Grid.Col span={4} key={index}>
                  <Flex justify="flex-start" direction="row">
                    <Button color="#536ded" fullWidth mr="sm">
                      {index + 1}-р түвшин
                    </Button>
                    {level?.level === values?.levels?.length && values?.levels?.length !== 3 ? (
                      <UnstyledButton
                        onClick={() => {
                          setFieldValue("levels", [
                            ...values.levels,
                            {
                              level: values.levels.length + 1,
                              userIds: undefined,
                            },
                          ]);
                        }}>
                        <IconCirclePlus size={35} stroke={1} color="#536ded" />
                      </UnstyledButton>
                    ) : null}

                    {level?.level === values?.levels?.length && values?.levels?.length !== 1 ? (
                      <UnstyledButton
                        onClick={() => {
                          onRemove(index, values.levels, setFieldValue);
                        }}
                        disabled={values?.levels?.length === 1}>
                        <IconTrash size={35} stroke={1} color="#536ded" />
                      </UnstyledButton>
                    ) : (
                      ""
                    )}
                  </Flex>
                  <Text c="dimmed" size="xs" style={{ textAlign: "justify" }} mt="sm">
                    Хяналтыг нэг түвшинд хийх бол нэмэх шаардлагагүй. Давхар хяналт хийх бол "+" сонгож хяналтын түвшнийг нэмнэ үү. Сүүлчийнх бол "Stop" сонгоно
                    уу.
                  </Text>
                  <Flex gap="md" justify="flex-start" direction="row">
                    <IconUserPlus stroke={1} color="#536ded" />
                    <UnstyledButton>
                      <Text color="#536ded">Хянах ажилтан</Text>
                    </UnstyledButton>
                    <UnstyledButton>
                      <IconSettings2 stroke={1} color="#536ded" />
                    </UnstyledButton>
                    <UnstyledButton>
                      <IconAlarm stroke={1} color="#536ded" />
                    </UnstyledButton>
                  </Flex>
                  <MultiSelectField
                    label={`${level.level}. Ажилтан`}
                    name={`levels[${index}].userIds`}
                    // options={datas.map((item: any) => {
                    //   return { value: item.id, label: `${item?.label}, ${item?.position}`, item };
                    // })}
                    options={users.map((item: any) => {
                      return { value: item.id, label: `${item?.firstName}`, item };
                    })}
                    placeholder="Сонгох"
                    itemComponent={SelectItem}
                    filter={(value: any, selected: any, item: any) =>
                      !selected &&
                      (item?.label?.toLowerCase().includes(value?.toLowerCase().trim()) ||
                        item?.description?.toLowerCase().includes(value.toLowerCase().trim()))
                    }
                  />
                </Grid.Col>
              ))}
            </Grid>
            <Divider color="gray" />
            <Flex direction="row" justify="space-between" gap={8}>
              <Button variant="default" onClick={() => setAction([])} loading={loading}>
                Болих
              </Button>
              <Button type="submit" variant="outline" loading={loading}>
                Хадгалах
              </Button>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
