import { AppShell, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconMoneybag, IconReceipt, IconUser, IconUserCheck } from "@tabler/icons-react";
import { NavLink, useLocation } from "react-router-dom";
import { useScreenDetector } from "../screen-detector";
import { CommingSoon } from "./comming-soon";

const items = [
  { title: "Системийн хэрэглэгч", icon: IconUser, color: "indigo", link: "/user" },
  { title: "Эрхийн тохиргоо", icon: IconUserCheck, color: "indigo", link: "/roles" },
  { title: "Санхүүжилт модуль", icon: IconMoneybag, color: "indigo", link: "/finance" },
];

export function Navigation({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) {
  const location = useLocation();
  const { isMobile } = useScreenDetector();

  let selected = location.pathname.split("/")[1] || "";

  return (
    <AppShell.Navbar p="md">
      {items.map((item, index) => (
        <NavItem item={item} key={index} selected={selected} setOpened={setOpened} opened={isMobile && opened} />
      ))}
    </AppShell.Navbar>
  );
}

type INavItem = {
  title: string;
  icon: typeof IconReceipt;
  color: string;
  link: string;
  disabled?: boolean;
};

function NavItem({ item, selected, setOpened, opened = false }: { item: INavItem; selected: any; setOpened: React.SetStateAction<any>; opened: boolean }) {
  const { classes, cx } = useStyles({ color: "indigo" });
  return (
    <CommingSoon position="right" visible={item.disabled}>
      <NavLink
        className={cx(classes.item, item.disabled && "disabled", item.link.split("/")[1] === selected && "active", opened && classes.row)}
        to={!item.disabled ? item.link : "#"}
        onClick={() => {
          setOpened(false);
        }}>
        <item.icon className={classes.icon} size={40} />
        <Text size="xs" mt={7} lh="16px" fw="400">
          {item.title}
        </Text>
      </NavLink>
    </CommingSoon>
  );
}

const useStyles = createStyles((theme, { color }: { color: string }, getRef) => {
  const icon = "icon";

  return {
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      marginBottom: "1rem",
      [`&.disabled .${icon}`]: {
        backgroundColor: `${theme.colors[color][0]}!important`,
        color: `${theme.colors[color][6]}!important`,
      },
      [`&:hover .${icon}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
      [`&.active .${icon}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
    },
    icon: {
      ref: icon,
      transition: "background-color 150ms ease, transform 100ms ease",
      color: theme.colors[color][6],
      backgroundColor: theme.colors[color][0],
      borderRadius: 4,
      padding: 8,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      gap: 15,
      justifyContent: "start",
      borderRadius: theme.radius.sm,

      [`&:hover`]: {
        background: theme.colors[color][0],
        transform: "revert",
        animation: "ease-in",
      },
    },
  };
});
