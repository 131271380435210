import { Avatar, Button, Divider, Flex, Grid, Group, Input, Text, Textarea } from "@mantine/core";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { Tag } from "../tags";

type Props = {
  action: any[];
  setAction: any;
  reload: any;
};

export function ApprovelDetail({ action, setAction, reload }: Props) {
  const { actions, modules } = useSelector((state: { general: IGeneral }) => state.general);

  const moduleStatus = (val: string) => {
    return (
      <Tag
        color={modules.find((item: IReference) => item.code === val)?.color || "blue"}
        name={modules.find((item: IReference) => item.code === val)?.name || "-"}
      />
    );
  };

  const systemActionStatus = (val: string) => {
    return <Tag color={actions.find((item: any) => item.code === val)?.color || "blue"} name={actions.find((item: any) => item.code === val)?.name || "-"} />;
  };

  return (
    <Flex sx={{ position: "relative" }} direction="column" gap="md">
      <Grid>
        <Grid.Col span={4}>
          <Input.Wrapper label="Бизнесийн нэр:">
            <Text fw={500} size={"sm"} c="gray">
              {`${action[1]?.business?.refCode}`} {`${action[1]?.business?.profileName}`}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Input.Wrapper label="Хамрах хүрээ:">
            <Text fw={500} size={"sm"} c="gray">
              {action[1]?.branch?.name || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Input.Wrapper label="Системийн модуль:">
            <Text fw={500} size={"sm"} c="gray">
              {moduleStatus(action[1]?.module)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Input.Wrapper label="Системийн үйлдэл:">
            <Text fw={500} size={"sm"} c="gray">
              {systemActionStatus(action[1]?.systemAction)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Input.Wrapper label="Дүрмийн нэр:">
            <Text fw={500} size={"sm"} c="gray">
              {action[1]?.name || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Input.Wrapper label="Тайлбар оруулах:">
            <Textarea value={action[1]?.description} onChange={() => {}} />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={12}>
          <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХЯНАЛТЫН ДҮРЭМ" />
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex justify="flex-start" direction="row" mb="sm">
            <Button color="#536ded" fullWidth mr="sm">
              {1}-р түвшин
            </Button>
          </Flex>
          <Group mt="sm">
            <Avatar src={`${action[1]?.regUser?.avatar}`} />
            <Text size="sm" fw={500}>
              {action[1]?.regUser?.lastName ? action[1]?.regUser?.lastName[0] + ". " : ""} {action[1]?.regUser?.firstName || "-"}
            </Text>
          </Group>
        </Grid.Col>
      </Grid>
      <Divider color="gray" />
      <Flex direction="row" justify="space-between">
        <Button variant="default" onClick={() => setAction([])}>
          Болих
        </Button>
      </Flex>
    </Flex>
  );
}
