import { ActionIcon, Avatar, Badge, Button, CloseButton, Divider, Drawer, Flex, Image, Modal, Paper, Text } from "@mantine/core";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTelegram,
  IconBrandTwitter,
  IconEdit,
  IconMailForward,
  IconPhoneCall,
  IconPlus,
} from "@tabler/icons-react";
import React, { ReactNode, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { UserApi } from "../../apis";
import { FormLayout, PageLayout } from "../../components/layout";
import { useScreenDetector } from "../../components/screen-detector";
import { ColumnType, Table } from "../../components/table";
import { RowActionIcon } from "../../components/table/row-action-icon";
import { Tag } from "../../components/tags";
import { InviteForm } from "../../components/user-management/invite-form";
import { UserForm } from "../../components/user-management/user-form";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { IUser } from "../../interfaces/IUser";
import { dateFormat } from "../../utils/date";

export function UserList() {
  const breadcrumbs = useBreadcrumb();
  const [filters] = React.useState({ query: "" });
  const [action, setAction] = useState<any[]>([]);
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);
  const { isMobile } = useScreenDetector();

  const { employeeUnits, userStatus, userRegisterStatus, systemUsageTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    systemUsageTypes,
    employeeUnits,
    userStatus,
    userRegisterStatus,
    onClick: (key, item: any) => {
      switch (key) {
        case "edit": {
          navigate(`/user/${item.id}`);
          break;
        }
        case "view_front": {
          setAction(["view_front", item]);
          break;
        }
        case "view_back": {
          setAction(["view_back", item]);
          break;
        }
        case "view_image": {
          setAction(["view_image", item]);
          break;
        }

        case "invite": {
          setAction(["invite", item]);
          break;
        }
      }
    },
    icons: (item: any) => {
      if (item.type === "TELEGRAM")
        return (
          <ActionIcon
            onClick={() => {
              window?.open(item.link, "_blank");
            }}
            bg={"linear-gradient(to bottom, #28A7E8 0%, #2699d2 100%)"}
            variant="gradient">
            <IconBrandTelegram size={18} />
          </ActionIcon>
        );
      if (item.type === "FACEBOOK")
        return (
          <ActionIcon
            onClick={() => {
              window?.open(item.link, "_blank");
            }}
            bg={"linear-gradient(to bottom, #3A5795 0%, #2B4886 100%)"}
            variant="gradient">
            <IconBrandFacebook size={18} />
          </ActionIcon>
        );
      else if (item.type === "VIBER")
        return (
          <ActionIcon
            onClick={() => {
              window?.open(item.link, "_blank");
            }}
            bg={"#7460F2"}
            variant="gradient">
            <IconPhoneCall size={18} />
          </ActionIcon>
        );
      else if (item.type === "INSTAGRAM")
        return (
          <ActionIcon
            onClick={() => {
              window?.open(item.link, "_blank");
            }}
            bg={"linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"}
            variant="gradient">
            <IconBrandInstagram size={18} />
          </ActionIcon>
        );
      else if (item.type === "TWITTER")
        return (
          <ActionIcon
            onClick={() => {
              window?.open(item.link, "_blank");
            }}
            bg={"#1D9BF0"}
            variant="gradient">
            <IconBrandTwitter size={18} />
          </ActionIcon>
        );
      else {
      }
    },
  });

  const reload = async () => {
    await tableRef.current?.reload();
  };

  return (
    <PageLayout
      title="Системийн хэрэглэгч"
      subTitle="Системийн хэрэглэгчийн жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[
        isMobile ? (
          <ActionIcon onClick={() => setAction(["new"])} variant="filled" color="">
            <IconPlus />
          </ActionIcon>
        ) : (
          <Button onClick={() => setAction(["new"])}>Хэрэглэгч нэмэх</Button>
        ),
      ]}>
      <Divider mb="lg" />
      <Table ref={tableRef} name="user.management.list" columns={columns} filters={filters} loadData={(data) => UserApi.list(data!)} />
      <Drawer
        withCloseButton={false}
        size={"70rem"}
        position="right"
        opened={action[0] === "new" || action[0] === "edit"}
        title={false}
        onClose={() => {
          setAction([]);
        }}>
        <Paper px="lg" py={"sm"}>
          <PageLayout title="Шинэ хэрэглэгч нэмэх" extra={[<CloseButton onClick={() => setAction([])} />]}>
            <Divider mb="lg" />
            <UserForm
              action={action}
              onCancel={() => {
                setAction([]);
              }}
              reload={() => reload()}
            />
          </PageLayout>
        </Paper>
      </Drawer>

      <Modal centered title={"Үнэмлэхний нүүр тал"} opened={action[0] === "view_front"} onClose={() => setAction([])}>
        <Image src={action[1] && action[1].identityCardFront} />
      </Modal>
      <Modal centered title={"Үнэмлэхний ар тал"} opened={action[0] === "view_back"} onClose={() => setAction([])}>
        <Image src={action[1] && action[1].identityCardBack} />
      </Modal>
      <Modal centered title={"Зураг"} opened={action[0] === "view_image"} onClose={() => setAction([])}>
        <Image src={action[1] && action[1].avatar} />
      </Modal>
      <Modal size={1000} withCloseButton={false} centered title={false} opened={action[0] === "invite"} onClose={() => setAction([])} padding={0}>
        <Paper px={"lg"} py={"md"}>
          <FormLayout
            title={"Систем ашиглах урилга илгээх"}
            subTitle={"Урилга хүлээн авах хүний мэдээллийг нягтлана уу."}
            extra={[
              <div key={0}>
                <CloseButton />
              </div>,
            ]}>
            <InviteForm
              action={action}
              onCancel={() => {
                setAction([]);
              }}
              reload={() => {
                reload();
              }}
            />
          </FormLayout>
        </Paper>
      </Modal>
    </PageLayout>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IUser) => void;
  employeeUnits: { id: string; name: string }[];
  userRegisterStatus: IReference[];
  userStatus: IReference[];
  icons: (e: string) => ReactNode;
  systemUsageTypes: IReference[];
};

const useHeader = ({ onClick, icons, employeeUnits, userRegisterStatus, userStatus, systemUsageTypes }: HeaderProps): ColumnType<IUser>[] => [
  {
    title: "Үйлдэл",
    align: "right",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <RowActionIcon
          hideKeys={record?.registerStatus === "SUCCESS" ? ["invite"] : []}
          onClick={(key) => onClick(key, record)}
          extra={[
            {
              text: "Засах",
              icon: <IconEdit />,
              key: "edit",
            },
            {
              text: "Урилга илгээх",
              icon: <IconMailForward />,
              key: "invite",
            },
          ]}
        />
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "image",
    render: (record) => {
      return (
        <ActionIcon
          disabled={!record.avatar}
          onClick={() => {
            onClick("view_image", record);
          }}>
          <Avatar radius={4} src={record?.avatar} />
        </ActionIcon>
      );
    },
  },
  {
    title: "Нэвтрэх нэр",
    sorter: true,
    dataIndex: "username",
    render: (record) => `${record?.username}`,
  },
  {
    title: "Овог",
    sorter: true,
    dataIndex: "lastName",
    render: (record) => `${record?.lastName || "-"}`,
  },
  {
    title: "Өөрийн нэр",
    sorter: true,
    dataIndex: "firstName",
    render: (record) => `${record?.firstName}`,
  },
  {
    title: "И-мэйл хаяг 1",
    sorter: true,
    dataIndex: "email",
    render: (record) => `${record?.email || "-"}`,
  },
  {
    title: "Утас 1",
    sorter: true,
    dataIndex: "phone",
    render: (record) => `${record?.phone || "-"}`,
  },

  {
    title: "Хэрэглэгчийн статус",
    sorter: true,
    dataIndex: "status",
    render: (record) => {
      return (
        <Badge color={record.userStatus === "ACTIVE" ? "green" : "gray"}>
          {userStatus.find((item: any) => item.code === record.userStatus)?.name || record?.userStatus || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Регистр №",
    sorter: true,
    dataIndex: "status",
    render: (record) => {
      return <Text>{record?.registerNo || "-"}</Text>;
    },
  },
  {
    title: "Ургийн овог",
    sorter: true,
    dataIndex: "phone",
    render: (record) => <Text>{record?.familyName || "-"}</Text>,
  },
  {
    title: "Иргэний үнэмлэх-нүүр",
    sorter: true,
    dataIndex: "phone",
    render: (record) => {
      return (
        <ActionIcon
          disabled={!record.identityCardFront}
          w={"auto"}
          h={"auto"}
          onClick={() => {
            onClick("view_front", record);
          }}>
          <Avatar radius={2} w={65} h={40} src={record?.identityCardFront} />
        </ActionIcon>
      );
    },
  },
  {
    title: "Иргэний үнэмлэх-ар",
    sorter: true,
    dataIndex: "phone",
    render: (record) => {
      return (
        <ActionIcon
          disabled={!record.identityCardBack}
          w={"auto"}
          h={"auto"}
          onClick={() => {
            onClick("view_back", record);
          }}>
          <Avatar radius={2} w={65} h={40} src={record?.identityCardBack} />
        </ActionIcon>
      );
    },
  },
  {
    title: "Харьяалах нэгж",
    sorter: true,
    dataIndex: "phone",
    render: (record) => <Text>{record?.departmentUnit?.name || "-"}</Text>,
  },
  {
    title: "Харьяалах дэд нэгж",
    sorter: true,
    dataIndex: "phone",
    render: (record) => <Text>{record?.departmentSubUnit?.name || "-"}</Text>,
  },
  {
    title: "Албан тушаал",
    sorter: true,
    dataIndex: "employeeUnitId",
    render: (record) => <Text>{record?.employeeUnit?.name || "-"}</Text>,
  },
  {
    title: "И-мэйл хаяг 2",
    sorter: true,
    dataIndex: "email",
    render: (record) => <Text>{record?.email2 || "-"}</Text>,
  },
  {
    title: "Утас 2",
    sorter: true,
    dataIndex: "phone",
    render: (record) => <Text>{record?.phone2 || "-"}</Text>,
  },
  {
    title: "Харилцах суваг",
    sorter: true,
    dataIndex: "date",
    render: (record) => {
      return (
        <Flex align="center" gap={8}>
          {record?.socialLinks?.length
            ? record?.socialLinks?.map((item: any, i: number) => {
                return icons(item);
              })
            : "-"}
        </Flex>
      );
    },
  },
  {
    title: "Бүртгэсэн",
    sorter: true,
    dataIndex: "date",
    render: (record) => <Text>{dateFormat(record?.createdAt)}</Text>,
  },
  {
    title: "Бүртгэл статус (баталгаажсан)",
    sorter: true,
    dataIndex: "status",
    render: (record) => {
      return (
        <Tag
          color={userRegisterStatus.find((item: any) => item.code === record.registerStatus)?.color ?? "gray"}
          name={userRegisterStatus.find((item: any) => item.code === record.registerStatus)?.name ?? "-"}
        />
      );
    },
  },
  {
    title: "Утас (баталгаажсан)",
    sorter: true,
    dataIndex: "isPhoneVerified",
    render: (record) =>
      record?.isPhoneVerified ? (
        <Badge variant="filled" color="green">
          Тийм
        </Badge>
      ) : (
        <Badge variant="filled" color="red.5">
          Үгүй
        </Badge>
      ),
  },
  {
    title: "И-мэйл (баталгаажсан)",
    sorter: true,
    dataIndex: "isEmailVerified",
    render: (record) =>
      record?.isEmailVerified ? (
        <Badge variant="filled" color="green">
          Тийм
        </Badge>
      ) : (
        <Badge variant="filled" color="red.5">
          Үгүй
        </Badge>
      ),
  },
  {
    title: "ДАН систем (баталгаажсан)",
    sorter: true,
    dataIndex: "isDanVerified",
    render: (record) =>
      record?.isDanVerified ? (
        <Badge variant="filled" color="green">
          Тийм
        </Badge>
      ) : (
        <Badge variant="filled" color="red.5">
          Үгүй
        </Badge>
      ),
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгч удирдлага",
  },
  {
    label: "Системийн хэрэглэгчид",
  },
];

export const USER_REGISTER_STATUS = {
  DRAFT: {
    code: "DRAFT",
    name: "Драфт",
    color: "gray.5",
  },
  INVITED: {
    code: "INVITED",
    name: "Урьсан",
    color: "indigo",
  },
  PARTIAL: {
    code: "PARTIAL",
    name: "Дутуу",
    color: "red.5",
  },
  SUCCESS: {
    code: "SUCCESS",
    name: "Амжилттай",
    color: "green",
  },
};
