import { Select } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
  noError?: boolean;
  options: { value: string; label: string }[];
  clearable?: boolean;
  classNames?: Partial<Record<string, string>> | undefined;
  searchable?: boolean;
  onSearchChange?: any;
  icon?: any;
  required?: boolean;
  itemComponent?: any;
  searchValue?: string;
  nothingFound?: any;
  filter?: any;
};

export function SelectField({
  name,
  label,
  placeholder,
  noError = false,
  onChange: onChangeValue,
  options = [],
  disabled = false,
  classNames,
  clearable = true,
  searchable,
  onSearchChange,
  icon,
  itemComponent,
  required = false,
  searchValue,
  nothingFound,
  filter,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Select
      filter={filter}
      nothingFoundMessage={nothingFound}
      searchValue={searchValue}
      leftSection={icon}
      label={label}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      searchable={searchable}
      onChange={(value) => {
        onChange(value);
        onChangeValue && onChangeValue(value);
      }}
      error={error}
      required={required}
      clearable={clearable}
      data={options}
      style={{ flex: 1 }}
      classNames={classNames}
      component={itemComponent}
      onSearchChange={(e) => {
        onSearchChange && onSearchChange(e);
      }}
      styles={{
        error: {
          display: noError ? "none" : "block",
        },
        wrapper: {
          marginBottom: noError && error && 0,
        },
      }}
    />
  );
}
