import { ActionIcon, Avatar, Badge, Button, CloseButton, Divider, Flex, Group, Modal, Paper, Text, Tooltip } from "@mantine/core";
import { IconInfoSquare, IconPlus } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { ApprovelApi } from "../../apis";
import { ApprovelDetail } from "../../components/approvel/detail";
import { ApprovelRuleForm } from "../../components/approvel/form";
import { FormLayout, PageLayout } from "../../components/layout";
import { PopoverText } from "../../components/popover-text/popover-text";
import { useScreenDetector } from "../../components/screen-detector";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IApprovelRuleLevel } from "../../interfaces/IApprovelRuleLevel";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { dateSecFormat } from "../../utils/date";

export function RuleList() {
  const breadcrumbs = useBreadcrumb();
  const [action, setAction] = React.useState<string[]>([]);
  const tableRef = React.useRef<ITableRef>(null);
  const { isMobile } = useScreenDetector();
  const [filters] = React.useState({ query: "" });
  const { modules, actions } = useSelector((state: { general: IGeneral }) => state.general);

  const moduleStatus = (val: string) => {
    return (
      <Tag
        color={modules.find((item: IReference) => item.code === val)?.color ?? "blue"}
        name={modules.find((item: IReference) => item.code === val)?.name ?? "-"}
      />
    );
  };

  const systemActionStatus = (val: string) => {
    return <Tag color={actions.find((item: any) => item.code === val)?.color ?? "blue"} name={actions.find((item: any) => item.code === val)?.name ?? "-"} />;
  };

  const columns = useHeader({
    moduleStatus,
    systemActionStatus,
    onClick: (key, item) => {
      switch (key) {
        case "detail": {
          setAction(["detail", item as any]);
          break;
        }
      }
    },
  });

  const reload = async () => {
    await tableRef.current?.reload();
  };

  return (
    <>
      <PageLayout
        title="Хяналтын дүрэм"
        subTitle="Хяналтын түвшинтэй үйлдлүүдэд хяналтын дүрэм тохируулгын жагсаалт"
        breadcrumb={breadcrumbs}
        extra={[
          isMobile ? (
            <ActionIcon onClick={() => setAction(["new"])} key={0} variant="filled" color="">
              <IconPlus />
            </ActionIcon>
          ) : (
            <Button onClick={() => setAction(["new"])} key={0}>
              Дүрэм тохируулах
            </Button>
          ),
        ]}>
        <Divider mb="lg" />
        <Table ref={tableRef} name="approval.rule.list" columns={columns} filters={filters} loadData={(data) => ApprovelApi.list(data!)} />
      </PageLayout>

      <Modal centered opened={action[0] === "new"} onClose={() => setAction([])} withCloseButton={false} size="70rem" padding={0}>
        <Paper px="lg" py={"xs"}>
          <FormLayout
            title="Хяналтын дүрэм тохируулах"
            subTitle="Хяналтын эрх хамаарах бизнес, системийн модуль"
            extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
            <ApprovelRuleForm action={action} reload={reload} setAction={setAction} />
          </FormLayout>
        </Paper>
      </Modal>
      <Modal centered opened={action[0] === "detail"} onClose={() => setAction([])} withCloseButton={false} size="70rem" padding={0}>
        <Paper px="lg" py={"xs"}>
          <FormLayout
            title="Хяналтын дүрэм тохируулах"
            subTitle="Хяналтын эрх хамаарах бизнес, системийн модуль дэлгэрэнгүй"
            extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
            <Divider color="gray" mb="md" />
            <ApprovelDetail action={action} setAction={setAction} reload={reload} />
          </FormLayout>
        </Paper>
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IApprovelRuleLevel) => void;
  moduleStatus: (key: string) => JSX.Element;
  systemActionStatus: (key: string) => JSX.Element;
};

const useHeader = ({ onClick, moduleStatus, systemActionStatus }: HeaderProps): ColumnType<IApprovelRuleLevel>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    align: "center",
    render: (record) => {
      return (
        <Tooltip label="Дэлгэрэнгүй">
          <ActionIcon variant="light" onClick={() => onClick("detail", record)} color="">
            <IconInfoSquare />
          </ActionIcon>
        </Tooltip>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "business",
    render: (record) => {
      return record?.business ? (
        <>
          <Flex gap={5} wrap="nowrap">
            <Text size="sm" fw={500}>
              {record?.business?.profileName || "-"}
            </Text>
          </Flex>
          <Text c="indigo"># {record?.business?.refCode || "-"}</Text>
        </>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Бизнес төрөл",
    sorter: true,
    dataIndex: "isDefault",
    render: (record) => `${"-"}`,
  },
  {
    title: "Хамрах хүрээ",
    sorter: true,
    dataIndex: "branch",
    render: (record) => {
      return <Text>{record?.branch?.name || "-"}</Text>;
    },
  },
  {
    title: "Систем модуль",
    sorter: true,
    dataIndex: "module",
    render: (record) => {
      return moduleStatus(record?.module);
    },
  },
  {
    title: "Системийн үйлдэл",
    sorter: true,
    dataIndex: "systemAction",
    render: (record) => {
      return systemActionStatus(record?.systemAction);
    },
  },
  {
    title: "Дүрмийн нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => <Text>{record?.name}</Text>,
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => <PopoverText>{record?.description}</PopoverText>,
  },
  {
    title: "Батлах үе шат",
    sorter: true,
    dataIndex: "isDefault",
    render: (record) => `${record?.level || "-"}`,
  },
  {
    title: "Төлөв",
    sorter: true,
    dataIndex: "isActive",
    render: (record) => {
      return record?.isActive ? (
        <Badge size="md" color="green" radius="sm" variant="outline">
          Идэвхтэй
        </Badge>
      ) : (
        <Badge size="md" color="red" radius="sm" variant="outline">
          Идэвхгүй
        </Badge>
      );
    },
  },
  {
    title: "Тохируулсан админ",
    sorter: true,
    dataIndex: "regUser",
    render: (record) => {
      return record?.regUser ? (
        <Group>
          <Avatar src={`${record?.regUser?.avatar}`} radius={"xl"} />
          <Flex gap={5} wrap="nowrap">
            <Text size="sm" fw={500}>
              {record?.regUser?.lastName ? record?.regUser?.lastName[0] + ". " : ""} {record?.regUser?.firstName || "-"}
            </Text>
          </Flex>
        </Group>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Огноо цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => <Text c="gray.7">{dateSecFormat(record?.createdAt)}</Text>,
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгч удирдлага",
  },
  {
    label: "Хяналтын дүрэм",
  },
];
