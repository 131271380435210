import { Alert, Button, Center, Collapse, Divider, Group, Input, Modal, Paper, Space, Tabs, Text } from "@mantine/core";
import { IconChartDots3 } from "@tabler/icons-react";
import React, { useState } from "react";
import * as yup from "yup";
import { AuthApi, FinanceSettingsApi } from "../../apis";
import { BankRoleForm } from "../../components/finance/bank-role-form";
import { RoleHistoryList } from "../../components/finance/role-history";
import { ScfDcfForm } from "../../components/finance/scf-dcf-form";
import { ScfDcfList } from "../../components/finance/scf-dcf-list";
import { Form } from "../../components/form";
import { OtpField } from "../../components/form/otp-field";
import { PageLayout } from "../../components/layout";
import { ITableRef } from "../../components/table";
import { dateTimeFormat } from "../../utils/date";
import { message } from "../../utils/message";

export function FinanceManagementList() {
  const breadcrumbs = useBreadcrumb();
  const [action, setAction] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tableRefScfDcf = React.useRef<ITableRef>(null);

  const tableRefRoleHistory = React.useRef<ITableRef>(null);
  const [tabKey, setTabKey] = useState<string>("1");

  return (
    <>
      <PageLayout
        title="Санхүүжилт модулийн тохиргоо"
        subTitle="Санхүүжилт модульд ажиллаж банк, санхүү харилцах тохиргоо"
        breadcrumb={breadcrumbs}
        extra={[
          <Collapse in={tabKey === "2"} key={0}>
            <Button onClick={() => setAction(["bank-role"])} key={0}>
              Банк эрх нэмэх
            </Button>
          </Collapse>,
        ]}>
        <Divider mb="lg" />
        <Tabs
          onChange={(e: string | null) => {
            if (e !== null) {
              setTabKey(e);
            }
          }}
          value={tabKey}
          defaultValue={"1"}>
          <Tabs.List>
            <Tabs.Tab value="1">SCF/DCF банк</Tabs.Tab>
            <Tabs.Tab value="2">Эрхийн түүх</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="1">
            <Space h={"lg"} />
            {tabKey === "1" && <ScfDcfList tableRef={tableRefScfDcf} action={action} setAction={setAction} />}
          </Tabs.Panel>
          <Tabs.Panel value="2">
            <Space h={"lg"} />
            {tabKey === "2" && <RoleHistoryList action={action} setAction={setAction} tableRef={tableRefRoleHistory} />}
          </Tabs.Panel>
          <Tabs.Panel value="3">
            <Space h={"lg"} />
            <Paper bg={"gray.0"} w="100%" p={"lg"}>
              <Center>
                <Text size={"sm"} color="gray">
                  Юу ч олдсонгүй
                </Text>
              </Center>
            </Paper>
          </Tabs.Panel>
        </Tabs>
      </PageLayout>

      <Modal opened={action[0] === "bank-role"} onClose={() => setAction([])} centered title={false} withCloseButton={false} size={"70rem"} padding={0}>
        <Paper px={"lg"} py={"xs"}>
          <BankRoleForm action={action} setAction={setAction} reload={async () => await tableRefScfDcf.current?.reload()} />
        </Paper>
      </Modal>

      <Modal
        centered
        opened={action[0] === "request"}
        onClose={() => setAction([])}
        withCloseButton={false}
        size="35rem"
        title={
          <Group>
            <IconChartDots3 size={"1.5rem"} color="blue" />
            <Text fw={600}>Finance модулийн хүсэлт</Text>
          </Group>
        }>
        <ScfDcfForm action={action} setAction={setAction} reload={async () => await tableRefScfDcf.current?.reload()} />
      </Modal>

      <Modal
        centered
        opened={action[0] === "role-pin"}
        onClose={() => setAction([])}
        withCloseButton={false}
        size="35rem"
        title={
          <Group>
            <IconChartDots3 size={"1.5rem"} color="blue" />
            <Text fw={600}>Finance модулийн хүсэлт</Text>
          </Group>
        }>
        <Form
          initialValues={{
            ...(action[1] && action[1]),
            pin: undefined,
          }}
          onSubmit={async (values) => {
            setIsLoading(true);
            try {
              let res = await AuthApi.checkPin({ pin: `${values.pin}` });
              if (res?.success) {
                await FinanceSettingsApi.assign({ bank: values.bank, userId: values.userId });
                const now = new Date();

                message.success(`Та ажилтанд Finance модульд хандах эрх олголоо. Банк зөвшөөрөх хариу өгтөл түр хүлээнэ үү. ${dateTimeFormat(now)} `);
                await tableRefRoleHistory.current?.reload();
                setAction([]);
              }
            } catch (error: any) {
              message.error(error.message || "Хүсэлт амжилтгүй");
            }
            setIsLoading(false);
          }}
          validationSchema={yup.object({
            pin: yup
              .string()
              .matches(/^[0-9]{6}$/, "Заавал бөглөнө!")
              .typeError("Зөвхөн тоон утга оруулна")
              .max(6, "Хэт урт sбайна!")
              .min(6, "Заавал бөглөнө!")
              .required("Заавал бөглөнө!")
              .nullable(),
          })}>
          {({ values }) => {
            return (
              <div>
                <Alert>
                  <Text component="span">
                    Та доорх ажилтанд Голомт банктай санхүүжилтийн үйлчилгээгээр харилцах эрх олгох гэж байна. Итгэлтэй байвал ПИН Кодоор баталгаажуулна уу.
                  </Text>
                </Alert>
                <Space h={"lg"} />

                <Input.Wrapper label="Ажилтны бүтэн овог нэр">
                  <Input value={`${values?.lastName || ""} ${values?.firstName}`} readOnly />
                </Input.Wrapper>

                <Space h={"lg"} />
                <OtpField name="pin" label="Итгэлтэй бол ПИН кодоо оруулж баталгаажуулна уу:" size="md" required />
                <Space h={"lg"} />
                <Group>
                  <Button onClick={() => setAction([])} variant="default">
                    Буцах
                  </Button>
                  <Button loading={isLoading} type="submit">
                    Зөвшөөрөх
                  </Button>
                </Group>
              </div>
            );
          }}
        </Form>
      </Modal>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгч удирдлага",
  },
  {
    label: "Санхүүжилт модуль",
  },
];
