import { Button, Divider, Flex, Grid } from "@mantine/core";
import { IconBrandFacebook, IconBrandInstagram, IconBrandTelegram, IconBrandTwitter, IconPhoneCall } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { UserApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { message } from "../../utils/message";
import { Form } from "../form";
import { DatePickerField } from "../form/date-picker-field";
import { ImageField } from "../form/image-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { IdentityCardUpload } from "../upload/identity-card-upload";

type IFormData = {
  familyName: string | undefined;
  lastName: string | undefined;
  firstName: string | undefined;
  registerNo: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  avatar: string | undefined;
  identityCardFront: string | undefined;
  identityCardBack: string | undefined;
  departmentUnitId: string | undefined;
  departmentSubUnitId: string | undefined;
  employeeUnitId: string | undefined;
  email2: string | undefined;
  phone2: string | undefined;
  socialLinks: [
    {
      type: string | undefined;
      link: string | undefined;
    },
  ];
};

const schema = yup.object({
  familyName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!")
    .trim(),
  firstName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!")
    .trim(),
  lastName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!"),
  registerNo: yup
    .string()
    .matches(/^[А-Яа-яA-Za-z|Үү|Өө|Ёё]{2}[0-9]{8}$/, "Зөвхөн регистр оруулна уу")
    .required("Заавал бөглөнө!")
    .trim(),
  email: yup.string().email("Email оруулна уу").required("Заавал бөглөнө!").nullable().trim(),
  phone: yup
    .string()
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Зөвхөн дугаар оруулна уу")
    .required("Заавал бөглөнө!")
    .nullable(),
  identityCardFront: yup.string().required("Заавал бөглөнө!").nullable(),
  identityCardBack: yup.string().required("Заавал бөглөнө!").nullable(),
  email2: yup.string().email("Email оруулна уу").nullable(),
  phone2: yup
    .string()
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Зөвхөн дугаар оруулна уу")
    .nullable(),
  departmentUnitId: yup.string().required("Заавал бөглөнө!").nullable(),
  departmentSubUnitId: yup.string().nullable(),
  avatar: yup.string().nullable().trim().optional(),
  socialLinks: yup
    .array()
    .of(
      yup.object().shape({
        link: yup.string().trim().nullable().optional(),
        type: yup.string().trim().nullable().optional(),
      }),
    )
    .nullable()
    .optional(),
});

export function UserForm({ action, onCancel, reload }: any) {
  const { departmentUnits, employeeUnits, socialTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [data] = React.useState<IFormData>({
    familyName: undefined,
    lastName: undefined,
    firstName: undefined,
    registerNo: undefined,
    email: undefined,
    phone: undefined,
    avatar: undefined,
    identityCardFront: undefined,
    identityCardBack: undefined,
    departmentUnitId: undefined,
    departmentSubUnitId: undefined,
    employeeUnitId: undefined,
    email2: undefined,
    phone2: undefined,
    socialLinks: socialTypes?.map((item: any) => {
      return {
        name: item.code,
        code: item.code,
        type: item.code,
        link: "",
      };
    }),
    ...(action[1] && {
      familyName: action[1]?.familyName,
      lastName: action[1]?.lastName,
      firstName: action[1]?.firstName,
      registerNo: action[1]?.registerNo,
      email: action[1]?.email,
      phone: action[1]?.phone,
      avatar: action[1]?.avatar || "",
      identityCardFront: action[1]?.identityCardFront,
      identityCardBack: action[1]?.identityCardBack,
      departmentUnitId: action[1]?.departmentUnit?.id,
      departmentSubUnitId: action[1]?.departmentSubUnit?.id,
      employeeUnitId: action[1]?.employeeUnit?.id,
      email2: action[1]?.email2,
      phone2: action[1]?.phone2,
      socialLinks: socialTypes?.map((item: any) => {
        return {
          name: item?.code,
          code: item?.code,
          type: item?.code,
          link: action[1]?.socialLinks?.find((item2: any) => item2?.type === item?.code)?.link || "",
        };
      }),
    }),
  });

  const icons = (type: string) => {
    if (type === "FACEBOOK") return <IconBrandFacebook size={18} />;
    else if (type === "VIBER") return <IconPhoneCall size={18} />;
    else if (type === "INSTAGRAM") return <IconBrandInstagram size={18} />;
    else if (type === "TWITTER") return <IconBrandTwitter size={18} />;
    else if (type === "TELEGRAM") return <IconBrandTelegram size={18} />;
  };

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    if (action[0] === "edit" && action[1]) {
      try {
        await UserApi.update(action[1].id, {
          ...values,
          socialLinks: values?.socialLinks
            ?.filter((item1: any) => !!item1.link?.trim())
            ?.map((item2: any) => {
              return {
                type: item2.type,
                link: item2.link?.trim(),
              };
            }),
          avatar: undefined,
        });
        reload();
        onCancel();
        setLoading(false);
        message.success("Хэрэглэгчийн мэдээлэл амжилттай засагдлаа");
      } catch (error: any) {
        setLoading(false);
        message.error(error.message || "Хэрэглэгчийн мэдээлэл засахад алдаа гарлаа!");
      }
    } else if (action[0] === "new") {
      try {
        await UserApi.create({
          ...values,
          socialLinks: values?.socialLinks
            ?.filter((item1: any) => !!item1.link?.trim())
            ?.map((item2: any) => {
              return {
                type: item2.type,
                link: item2.link?.trim(),
              };
            }),
        });
        reload();
        onCancel();
        setLoading(false);
        message.success("Хэрэглэгч амжилттай нэмлээ");
      } catch (error: any) {
        setLoading(false);
        message.error(error.message || "Хэрэглэгч нэмэхэд алдаа гарлаа!");
      }
    }
  };

  return (
    <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
      {({ values, setFieldValue, errors }) => {
        console.log("errors: ", errors);
        return (
          <Grid>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <TextField name="username" placeholder="Хэрэглэгчийн нэр" label="Системээс авто үүсгэнэ" disabled />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <TextField name="registeredAdmin" placeholder="Бүртгэсэн админ" label="Системээс авто үүсгэнэ" disabled />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <DatePickerField name="createdAt" placeholder="Бүртгэсэн огноо, цаг" label="Системээс авто үүсгэнэ" disabled />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <TextField name="familyName" label="Ургийн овог" placeholder="Оруулна уу" required />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <TextField name="lastName" label="Эцэг, эхийн нэр" placeholder="Оруулна уу" required />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <TextField name="firstName" label="Өөрийн нэр" placeholder="Оруулна уу" required />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <TextField name="registerNo" label="Регистрийн дугаар" placeholder="Оруулна уу" required />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <TextField name="email" label="И-мэйл №1 (бүртгэл баталгаажуулах)" placeholder="Оруулна уу" required />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <TextField type="number" name="phone" label="Гар утас №1 (Бүртгэл баталгаажуулах)" placeholder="Оруулна уу" required />
            </Grid.Col>
            <Grid.Col span={12}></Grid.Col>
            {action[0] !== "edit" && (
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <ImageField name="avatar" label="Профайл зураг" />
              </Grid.Col>
            )}
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <IdentityCardUpload name="identityCardFront" label="Иргэний үнэмлэх - Урд тал" />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <IdentityCardUpload name="identityCardBack" label="Иргэний үнэмлэх - Ард тал" />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <SelectField
                onChange={() => {
                  setFieldValue("employeeUnitId", undefined);
                  setFieldValue("departmentSubUnitId", undefined);
                }}
                options={departmentUnits.map((d: any) => ({ label: d.name, value: d.id }))}
                name="departmentUnitId"
                label="Харьяалах нэгж"
                placeholder="Сонгоно уу"
                required
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <SelectField
                disabled={!values.departmentUnitId}
                options={departmentUnits?.filter((c: any) => c.parentId === values.departmentUnitId).map((d: any) => ({ label: d.name, value: d.id }))}
                name="departmentSubUnitId"
                label="Харьяалах дэд нэгж"
                placeholder="Сонгоно уу"
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <SelectField
                disabled={!values.departmentSubUnitId}
                options={employeeUnits?.filter((c: any) => c.departmentUnitId === values.departmentUnitId).map((d: any) => ({ label: d.name, value: d.id }))}
                name="employeeUnitId"
                label="Албан тушаал"
                placeholder="Сонгоно уу"
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <TextField name="email2" label="И-мэйл №2" placeholder="Оруулна уу" />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <TextField type="number" name="phone2" label="Гар утас №2" placeholder="Оруулна уу" />
            </Grid.Col>
            <Grid.Col span={12} />
            {values?.socialLinks?.map((item: IReference, index: number) => {
              return (
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} key={index}>
                  <TextField
                    onChangeCustom={(e: any) => {
                      setFieldValue(`socialLinks[${index}].type`, item.code);
                    }}
                    icon={icons(item.code)}
                    name={`socialLinks[${index}].link`}
                    placeholder={item.name}
                    label={item.name}
                  />
                </Grid.Col>
              );
            })}
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
            <Grid.Col span={12}>
              <Flex justify="end" gap="md">
                <Button onClick={() => onCancel()} variant="default">
                  Буцах
                </Button>
                <Button loading={loading} type="submit">
                  Хадгалах
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}
