import { MultiSelect } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: string[] | null) => void;
  options: { value: string; label: string }[];
  defaultvalue?: string[];
  onlyRead?: boolean;
  itemComponent?: any;
  filter?: any;
};

export function MultiSelectField({
  name,
  label,
  placeholder,
  onChange: onChangeValue,
  options,
  disabled = false,
  defaultvalue = [],
  onlyRead,
  itemComponent = false,
  filter = () => {},
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <MultiSelect
      label={label}
      placeholder={placeholder}
      value={value || []}
      disabled={disabled}
      onChange={(values) => {
        if (!onlyRead) {
          onChange(values);
          onChangeValue && onChangeValue(values);
        }
      }}
      error={error}
      required
      clearable
      data={options}
      style={{ flex: 1 }}
      component={itemComponent && itemComponent}
      filter={filter && filter}
    />
  );
}
