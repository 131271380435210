import { Badge, Center, Divider, Flex, Grid, LoadingOverlay, Paper, Table, Tabs, Text } from "@mantine/core";
import { IconCheck, IconMailForward, IconPhone, IconShieldCheck, IconShieldX, IconUserCheck, IconUserCircle, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { UserApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { ImageUpload } from "../../components/upload/image-upload";
import { UserForm } from "../../components/user-management/user-form";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { message } from "../../utils/message";
import { RoleSettingsTab } from "./role-settings-tab";

const fallbackData = {
  type: undefined,
  partnerCategory: undefined,
  classification: undefined,
  regNumber: undefined,
  businessName: undefined,
  businessNameEng: undefined,
  legalEntityType: undefined,
  stateRegNum: undefined,
  stateRegDate: undefined,
  country: undefined,
  equityType: undefined,
  isVatPayer: false,
  web: undefined,
  email: undefined,
  email2: undefined,
  phone: undefined,
  phone2: undefined,
  fbLink: undefined,
  provinceId: undefined,
  userRoles: [],
};

export function UserProfile() {
  const breadcrumbs = useBreadcrumb();
  const params = useParams();
  const navigate = useNavigate();
  const [tabKey, setTabKey] = useState<string>("CORE_INFO");
  const { systemUsageTypes, userRegisterStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const { data, isLoading, mutate } = useSwr(`partner.back.office.get.${params.id}`, async () => await UserApi.get(params.id!), {
    fallbackData: fallbackData,
  });

  const changeAvatar = async (e: any) => {
    try {
      await UserApi.updateAvatar(params.id!, { avatar: e?.url });
      await mutate();
      message.success("Хэрэглэгчийн зураг амжилттай солигдлоо");
    } catch (error: any) {
      message.error(error.message ?? "Алдаа гарлаа");
    }
  };

  return (
    <PageLayout title="Хэрэглэгчийн профайл" subTitle="Системийн хэрэглэгчийн мэдээлэл, эрхийн мэдээлэл" breadcrumb={breadcrumbs}>
      <Divider mb="lg" />
      <Grid>
        <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 5, xl: 4 }}>
          <Paper withBorder h={"100%"} w="100%" p="xl">
            <Center>
              <Flex direction="column" gap={"sm"} align="center">
                <ImageUpload
                  value={data?.avatar ?? ""}
                  radius={150}
                  size={150}
                  onChange={(e) => {
                    changeAvatar(e);
                  }}
                />
                <Text fw={600} fz={"xl"} c="indigo" ta="center">
                  {data?.lastName} {data?.firstName}
                </Text>
                <Text ta="center" c="gray.8">
                  {data?.employeeUnit?.name}
                </Text>
              </Flex>
            </Center>

            <Divider
              label={
                <Text fw={500} fz="md" py={"md"} c="gray.8">
                  Холбоо барих
                </Text>
              }
            />
            <Flex gap={10} direction="column">
              <Flex align="center" gap={8}>
                <Flex>
                  <IconMailForward color="blue" size={18} />
                </Flex>
                <Text size={"sm"} c="gray.8">
                  {data?.email ?? "-"}
                </Text>
              </Flex>
              <Flex align="center" gap={8}>
                <Flex>
                  <IconPhone color="blue" size={18} />
                </Flex>
                <Text size={"sm"} c="gray.8">
                  {data?.phone ?? "-"}
                </Text>
              </Flex>
              <Flex align="center" gap={8}>
                <Flex>
                  <IconShieldX color="blue" size={18} />
                </Flex>
                <Text size={"sm"} c={data.usageType === "NONE" ? "red" : "gray.8"}>
                  {systemUsageTypes.find((item: IReference) => item.code === data.usageType)?.name ?? "Тохируулаагүй"}
                </Text>
              </Flex>
              <Flex direction="row" gap={8} wrap="nowrap">
                <Flex>
                  <IconUserCircle color="blue" size={18} />
                </Flex>
                <Flex wrap={"wrap"} gap={8} mt="2px">
                  {data?.userRoles?.map((item: any) => {
                    return <Badge key={item.id}>{item.name}</Badge>;
                  }) ?? "-"}
                </Flex>
              </Flex>
            </Flex>
            <Divider
              label={
                <Text fw={500} fz="md" py={"md"} c="gray.8">
                  Хэрэглэгчийн статус
                </Text>
              }
            />
            <>
              <Table c={"gray.8"} fs="md">
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td>
                      <Text size={"sm"}> Төлөв</Text>
                    </Table.Td>
                    <Table.Td>
                      <Flex gap={8} align="center">
                        <Badge color={userRegisterStatus.find((item: IReference) => item.code === data?.registerStatus)?.color ?? "gray"}>
                          {userRegisterStatus.find((item: IReference) => item.code === data?.registerStatus)?.name}
                        </Badge>
                      </Flex>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <Text size={"sm"}>Утас баталгаажилт</Text>
                    </Table.Td>
                    <Table.Td>
                      <Flex gap={8} align="center">
                        {data?.isPhoneVerified ? <IconCheck color="green" /> : <IconX color="red" />}
                      </Flex>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <Text size={"sm"}>И-мэйл баталгаажилт</Text>
                    </Table.Td>
                    <Table.Td>
                      <Flex gap={8} align="center">
                        {data?.isEmailVerified ? <IconCheck color="green" /> : <IconX color="red" />}
                      </Flex>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <Text size={"sm"}>ДАН систем баталгаажилт</Text>
                    </Table.Td>
                    <Table.Td>
                      <Flex gap={8} align="center">
                        {data?.isDanVerified ? <IconCheck color="green" /> : <IconX color="red" />}
                      </Flex>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <Text size={"sm"}>Нууц үг үүсгэсэн эсэх</Text>
                    </Table.Td>
                    <Table.Td>
                      <Flex gap={8} align="center">
                        {data?.hasPassword ? <IconCheck color="green" /> : <IconX color="red" />}
                      </Flex>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <Text size={"sm"}>ПИН код үүсгэсэн эсэх</Text>
                    </Table.Td>
                    <Table.Td>
                      <Flex gap={8} align="center">
                        {data?.hasPin ? <IconCheck color="green" /> : <IconX color="red" />}
                      </Flex>
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 7, xl: 8 }}>
          <Paper withBorder h={"100%"} px="xl" py="xs">
            <Tabs
              defaultValue={tabKey}
              value={tabKey}
              onChange={(e: string | null) => {
                if (e !== null) {
                  setTabKey(e);
                }
              }}>
              <Tabs.List>
                <Tabs.Tab p={"lg"} value="CORE_INFO" leftSection={<IconUserCheck size={22} />}>
                  Үндсэн мэдээлэл
                </Tabs.Tab>
                <Tabs.Tab value="ROLE_SETTINGS" leftSection={<IconShieldCheck size={22} />}>
                  Эрхийн тохиргоо
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="CORE_INFO" pt="sm">
                {!isLoading ? (
                  <UserForm
                    action={["edit", data]}
                    onCancel={() => {
                      navigate("/user");
                    }}
                    reload={async () => {
                      await mutate();
                    }}
                  />
                ) : (
                  <LoadingOverlay visible />
                )}
              </Tabs.Panel>

              <Tabs.Panel value="ROLE_SETTINGS" pt="xs">
                {!isLoading && (
                  <RoleSettingsTab
                    data={data}
                    reload={async () => {
                      await mutate();
                    }}
                  />
                )}
              </Tabs.Panel>

              <Tabs.Panel value="SYSTEM_HISTORY" pt="xs">
                <Center>
                  <Text mt={"lg"} c="dimmed">
                    Одоогоор түүх алга.
                  </Text>
                </Center>
              </Tabs.Panel>
            </Tabs>
          </Paper>
        </Grid.Col>
      </Grid>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгч удирдлага",
  },
  {
    to: "/user",
    label: "Системийн хэрэглэгчид",
  },
  {
    label: "Хэрэглэгчийн профайл",
  },
];
