import { Button, Divider, Flex, Grid } from "@mantine/core";
import { IconMailForward } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { UserApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { message } from "../../utils/message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";

type IFormData = {
  familyName: string | undefined;
  lastName: string | undefined;
  firstName: string | undefined;
  registerNo: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  avatar: string | undefined;
  identityCardFront: string | undefined;
  identityCardBack: string | undefined;
  departmentUnitId: string | undefined;
  departmentSubUnitId: string | undefined;
  employeeUnitId: string | undefined;
  email2: string | undefined;
  phone2: string | undefined;
  socialLinks: [
    {
      type: string | undefined;
      link: string | undefined;
    },
  ];
  id: string;
};

const schema = yup.object({});

export function InviteForm({ action, onCancel, reload }: any) {
  const { departmentUnits, employeeUnits } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [data] = React.useState<IFormData>({
    familyName: undefined,
    lastName: undefined,
    firstName: undefined,
    registerNo: undefined,
    email: undefined,
    phone: undefined,
    departmentUnitId: undefined,
    departmentSubUnitId: undefined,
    employeeUnitId: undefined,
    id: "im-not-user",
    ...(action[1] && action[1]),
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      await UserApi.send(values.id);
      reload();
      onCancel();
      setLoading(false);
      message.success("Урилга амжилттай илгээгдлээ");
    } catch (error: any) {
      setLoading(false);
      message.error(error.message || "Хүсэлтэд алдаа гарлаа!");
    }
  };

  return (
    <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
      {({ values, setFieldValue }) => {
        return (
          <Grid>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <TextField name="familyName" placeholder="Ургийн овог" label="Системээс авто үүсгэнэ" disabled />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <TextField name="lastName" placeholder="Эцэг, эхийн нэр" label="Системээс авто үүсгэнэ" disabled />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <TextField name="firstName" placeholder="Өөрийн нэр" label="Системээс авто үүсгэнэ" disabled />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <TextField name="registerNo" placeholder="Регистрийн дугаар" label="Системээс авто үүсгэнэ" disabled />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <TextField name="email" placeholder="И-мэйл №1 (бүртгэл баталгаажуулах)" label="Системээс авто үүсгэнэ" disabled />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <TextField name="phone" placeholder="Гар утас №1 (Бүртгэл баталгаажуулах)" label="Системээс авто үүсгэнэ" disabled />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <SelectField
                disabled
                options={departmentUnits.map((d: any) => ({ label: d.name, value: d.id }))}
                name="departmentUnitId"
                label="Харьяалах нэгж"
                placeholder="Системээс авто үүсгэнэ"
                required
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <SelectField
                disabled
                options={departmentUnits?.filter((c: any) => c.parentId === values.departmentUnitId).map((d: any) => ({ label: d.name, value: d.id }))}
                name="departmentSubUnitId"
                label="Харьяалах дэд нэгж"
                placeholder="Системээс авто үүсгэнэ"
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <SelectField
                disabled
                options={employeeUnits?.filter((c: any) => c.departmentUnitId === values.departmentUnitId).map((d: any) => ({ label: d.name, value: d.id }))}
                name="employeeUnitId"
                label="Албан тушаал"
                placeholder="Системээс авто үүсгэнэ"
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
            <Grid.Col span={12}>
              <Flex justify="end" gap="md">
                <Button onClick={() => onCancel()} variant="default">
                  Буцах
                </Button>
                <Button leftSection={<IconMailForward />} loading={loading} type="submit">
                  Урилга илгээх
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}
