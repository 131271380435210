import { Alert, Avatar, Button, CloseButton, Collapse, Divider, Flex, Grid, Group, Input, Paper, Radio, Space, Text } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconPhoto, IconQrcode } from "@tabler/icons-react";
import React, { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import * as yup from "yup";
import { FinanceSettingsApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { dateTimeFormat } from "../../utils/date";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  bank: yup.string().required("Заавал бөглөнө!").nullable(),
  userId: yup.string().required("Заавал бөглөнө!").nullable(),
  familyName: yup.string().when("userId", (userId, schema) => {
    if (userId) {
      return schema.required("Заавал бөглөнө!");
    } else {
      return schema;
    }
  }),
  lastName: yup
    .string()
    .when("userId", (userId, schema) => {
      if (userId) {
        return schema.required("Заавал бөглөнө!");
      } else {
        return schema;
      }
    })
    .optional(),
  username: yup
    .string()
    .when("userId", (userId, schema) => {
      if (userId) {
        return schema.required("Заавал бөглөнө!");
      } else {
        return schema;
      }
    })
    .optional(),
  registerNo: yup
    .string()
    .when("userId", (userId, schema) => {
      if (userId) {
        return schema.required("Заавал бөглөнө!");
      } else {
        return schema;
      }
    })
    .optional(),
  firstName: yup
    .string()
    .when("userId", (userId, schema) => {
      if (userId) {
        return schema.required("Заавал бөглөнө!");
      } else {
        return schema;
      }
    })
    .optional(),
});

interface ItemPropsUsr extends React.ComponentPropsWithoutRef<"div"> {
  avatar: string;
  firstName: string;
  lastName: string;
}

interface ItemPropsBank extends React.ComponentPropsWithoutRef<"div"> {
  bankCodeGolomt: string;
  bankCodeQpay: string;
  code: string;
  icon: string;
  name: string;
}

export function BankRoleForm({ action, setAction, reload }: { action: any[]; setAction: React.Dispatch<React.SetStateAction<any[]>>; reload: () => void }) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { bankNames } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading] = useState<boolean>(false);
  const [isHope, setIsHope] = useState<any>("");

  const [usrQuery, setUsrQuery] = useState<string>("");
  const [usrDebounced] = useDebouncedValue(usrQuery, 500, { leading: true });

  const { data: usrSelectList } = useSwr<any>(
    `/usr/finance_settings/finance_role/user_select?${JSON.stringify({
      query: usrDebounced,
    })}`,
    async () => await FinanceSettingsApi.user_select({ limit: 10, query: usrDebounced }),
    { fallback: [] },
  );

  const [data] = useState<any>({
    bank: null,
    userId: null,
    familyName: null,
    lastName: null,
    firstName: null,
    username: null,
    registerNo: null,
    email: null,
    email2: null,
    phone: null,
    phone2: null,
  });

  const [bankQuery, setBankQuery] = useState<string>("");
  const [bankDebounced] = useDebouncedValue(bankQuery, 500, { leading: true });

  const { data: bankSelectList } = useSwr<any>(
    `/usr/finance_settings/finance_role/bank_select?${JSON.stringify({
      query: bankDebounced,
    })}`,
    async () => await FinanceSettingsApi.bank_select({ limit: 10, query: bankDebounced }),
    { fallback: [] },
  );

  const onSubmit = async (values: any) => {
    console.log("vales: ", values);

    setAction(["role-pin", values]);
  };

  const SelectItemUsr = forwardRef<HTMLDivElement, ItemPropsUsr>(({ avatar, firstName, lastName, ...others }: ItemPropsUsr, ref) => (
    <div ref={ref} {...others}>
      <Group>
        <Avatar src={avatar} />
        <div>
          <Text tt={"capitalize"} size="sm">
            {firstName}
          </Text>
          <Text tt={"capitalize"} size="xs" opacity={0.65}>
            {lastName ? lastName[0] + ". " : ""} {firstName}
          </Text>
        </div>
      </Group>
    </div>
  ));

  const SelectItemBank = forwardRef<HTMLDivElement, ItemPropsBank>(({ bankCodeGolomt, bankCodeQpay, code, icon, name, ...others }: ItemPropsBank, ref) => (
    <div ref={ref} {...others}>
      <Group>
        <Avatar src={icon}>
          <IconPhoto />
        </Avatar>

        <div>
          <Text tt={"capitalize"} size="sm">
            {name}
          </Text>
          <Flex align="center" gap={4}>
            <IconQrcode size={"18px"} stroke={1} />
            <Text size="xs" opacity={0.65}>
              {bankCodeQpay}
            </Text>
          </Flex>
        </div>
      </Group>
    </div>
  ));

  return (
    <FormLayout
      title="Банктай харилцах ажилтны эрх тохируулах"
      subTitle="Банкны харилцах ажилтан нэг л байх ёстой учир эрх идэвхтэй хэрэглэгч байгаа бол идэвхгүй болгоно уу."
      extra={[<CloseButton key={0} onClick={() => setAction([])} />]}>
      <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
        {({ values, setFieldValue, errors, setItems }) => {
          return (
            <div>
              <Divider h={"lg"} />
              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <Input.Wrapper label="Тохироо хийж буй">
                    <Input
                      placeholder="[Хэрэглэгчийн овог]  [хэрэглэгчийн нэр]"
                      disabled
                      value={`${user?.lastName ? user?.lastName[0] + ". " : ""}${user?.firstName}`}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <Input.Wrapper label="Хэрэглэгчийн нэр">
                    <Input placeholder="Тохиргоо хийж буй хэрэглэгчийн Username" disabled value={user?.username} />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <Input.Wrapper label="Огноо, цаг">
                    <Input placeholder="Тохиргоо хийж буй огноо цаг" disabled value={`${dateTimeFormat(new Date())}`} />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <Input.Wrapper label="Партнерийн нэр">
                    <Input placeholder="Партнер код, партнер нэр" disabled value={user?.partner.businessName} />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <SelectField
                    nothingFound={"Хайлт байхгүй..."}
                    searchValue={bankQuery}
                    itemComponent={SelectItemBank}
                    onSearchChange={(e: any) => setBankQuery(e)}
                    clearable
                    name="bank"
                    label="Банкны нэр"
                    placeholder="Сонгох"
                    searchable
                    options={
                      bankSelectList?.map((item: any) => {
                        return {
                          label: bankNames.find((item2: any) => item2.code === item.bank).name || "",
                          value: item?.bank || "",
                          ...bankNames.find((item2: any) => item2.code === item.bank),
                        };
                      }) || []
                    }
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <SelectField
                    nothingFound="хайлт олдсонгүй"
                    clearable
                    itemComponent={SelectItemUsr}
                    name="userId"
                    label="Банкны харилцах ажилтан"
                    placeholder="Finance role-той ажилтнуудаас сонгох"
                    onSearchChange={(e: any) => {
                      setUsrQuery(e);
                    }}
                    onChange={(e) => {
                      if (e) {
                        setFieldValue("familyName", usrSelectList.find((item: any) => item.id === e)?.familyName || "");
                        setFieldValue("lastName", usrSelectList.find((item: any) => item.id === e)?.lastName || "");
                        setFieldValue("firstName", usrSelectList.find((item: any) => item.id === e)?.firstName || "");
                        setFieldValue("username", usrSelectList.find((item: any) => item.id === e)?.username || "");
                        setFieldValue("registerNo", usrSelectList.find((item: any) => item.id === e)?.registerNo || "");
                        setFieldValue("email", usrSelectList.find((item: any) => item.id === e)?.email || "");
                        setFieldValue("email2", usrSelectList.find((item: any) => item.id === e)?.email2 || "");
                        setFieldValue("phone", usrSelectList.find((item: any) => item.id === e)?.phone || "");
                        setFieldValue("phone2", usrSelectList.find((item: any) => item.id === e)?.phone2 || "");
                      } else {
                        setFieldValue("familyName", null);
                        setFieldValue("lastName", null);
                        setFieldValue("firstName", null);
                        setFieldValue("username", null);
                        setFieldValue("registerNo", null);
                        setFieldValue("email", null);
                        setFieldValue("email2", null);
                        setFieldValue("phone", null);
                        setFieldValue("phone2", null);
                      }
                    }}
                    searchValue={usrQuery}
                    searchable
                    options={
                      usrSelectList?.map((item: any) => {
                        return {
                          label: item?.firstName || "",
                          value: item?.id || "",
                          firstName: item?.firstName,
                          lastName: item?.lastName,
                          avatar: item?.avatar,
                        };
                      }) || []
                    }
                    required
                  />
                </Grid.Col>
                <Grid.Col>
                  <Space h={"lg"} />
                  <Text fz={"md"} fw={600}>
                    Банкны харилцах ажилтан
                  </Text>
                </Grid.Col>
                <Grid.Col>
                  <Paper p={"sm"} withBorder>
                    <Grid>
                      <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                        <Input.Wrapper label="Ургийн овог" required>
                          <TextField name={"familyName"} placeholder="-" disabled />
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                        <Input.Wrapper label="Эцэг/эхийн нэр" required>
                          <TextField name={"lastName"} placeholder="-" disabled />
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                        <Input.Wrapper label="Өөрийн нэр" required>
                          <TextField name={"firstName"} placeholder="-" disabled />
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                        <Input.Wrapper label="DeHUB username" required>
                          <TextField name={"username"} placeholder="-" disabled />
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                        <Input.Wrapper label="Хэрэглэгчийн Регистр дугаар" required>
                          <TextField name={"registerNo"} placeholder="-" disabled />
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                        <Input.Wrapper label="И-мэйл хаяг №1">
                          <TextField name={"email"} placeholder="-" disabled />
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                        <Input.Wrapper label="Хэрэглэгчийн утас №1">
                          <TextField name={"phone"} placeholder="-" disabled />
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                        <Input.Wrapper label="И-мэйл хаяг №2">
                          <TextField name={"email2"} placeholder="-" disabled />
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                        <Input.Wrapper label="Хэрэглэгчийн утас №2">
                          <TextField name={"phone2"} placeholder="-" disabled />
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col>
                        <Collapse in={errors.familyName || errors.lastName || errors.username || errors.registerNo || errors.userId}>
                          <Alert title="Заавал бөглөнө!" color="red">
                            {!errors.userId
                              ? "Уучлаарай, банкны харилцах ажилтан мэдээллээ бүрэн тохируулсан байх шаардлагатай."
                              : "Банкны харилцах ажилтан эхэлж сонгоно уу."}
                          </Alert>
                        </Collapse>
                      </Grid.Col>
                    </Grid>
                  </Paper>
                </Grid.Col>
                <Grid.Col>
                  <Space h={"xs"} />
                  <Alert title={<Text>Баталгаажилт хэсэг</Text>}>
                    <Text>Дээрх хэрэглэгчид гэрээний дагуу Finance модуль ашиглах эрхийг олгох гэж буйдаа итгэлтэй байна уу?</Text>
                  </Alert>
                  <Space h={"xs"} />
                  <Radio.Group value={isHope} onChange={(e) => setIsHope(e)}>
                    <Radio value={"yes"} label="Итгэлтэй байна" />
                    <Radio value={"no"} label="Итгэлгүй байна" />
                  </Radio.Group>
                </Grid.Col>
              </Grid>
              <Divider my={"lg"} />
              <Group>
                <Button onClick={() => setAction([])} variant="default">
                  Болих
                </Button>
                <Button disabled={isHope !== "yes"} loading={loading} type="submit">
                  Зөвшөөрөх
                </Button>
              </Group>
            </div>
          );
        }}
      </Form>
    </FormLayout>
  );
}
